@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;700&family=Montserrat:wght@100;300;400;500;600&display=swap');

@layer base {
  :root {
    --color-primary: #dcff50;
    --color-secondary: #7b61ff;
    --color-heading: #f5f5f7;
    --color-background: #090909;

  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cormorant-font{
    font-family: 'Cormorant Garamond', serif;
}

.menu-color {
  background-color: #201501;
}


.menu-color2 {
  background-color: #281b02;
}

.menu-color3 {
  background-color: #332203;
}

.main-color {
  background-color: #CA942B;
}

.main-color-light {
  background-color: #DFAB31;
}

.main-text-color {
  color: #CA942B;
}

.main-text-color-light {
  color: #DFAB31;
}

.text-color-dark {
  color: #808080;
}

.border-b-main {
  --tw-border-opacity: 1;
  border-bottom-color: #CA942B;
}

.border-main {
  --tw-border-opacity: 1;
  border-color: #CA942B;
}


.react-slideshow-container+ul.indicators .each-slideshow-indicator:before{
  position:absolute;
  top:0;
  left:0;
  width:17px !important;
  height:17px !important;
  border-radius:50%;
  content:'';
  background:#CA942B !important;
  text-align:center}

.react-slideshow-container+ul.indicators li {
  display: inline-block;
  position: relative;
  width: 17px !important;
  height: 17px !important;
  padding: 15px !important;
  margin: 0;
}



.hover\:bg-menu:hover {
  --tw-bg-opacity: 0.1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #201501;
  z-index: 2;
}

.h-screen-75{
  height: calc(100vh - 15vh);
}

.min-h-screen-75{
  min-height: calc(100vh - 15vh);
}

.h-screen-65{
  height: calc(100vh - 35vh);
}

.h-screen-50{
  height: calc(100vh - 50vh);
}

.main-gradient {
  background-image: linear-gradient(0deg, rgba(30, 16, 7, 0.61) 5.0%, rgba(19, 10, 4, 0.6) 87.46%);
}

/* Define a continuous rotation animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-element {
  animation: rotate 30s linear infinite; /* Adjust the duration and animation properties as needed */
}

.line {
  flex-grow: 1; /* Take up the remaining horizontal space */
  height: 1px; /* Adjust the line height */
  background-color: #ccc; /* Adjust the line color */
}

.line2 {
  flex-grow: 1; /* Take up the remaining horizontal space */
  height: 1px; /* Adjust the line height */
  background-color: #CA942B; /* Adjust the line color */
}

.leading-20 {
  line-height: 8rem/* 40px */;
}


.h-100 {
  height: 24rem/* 288px */;
}

.h-120 {
  height: 28rem/* 288px */;
}

.h-140 {
  height: 40rem/* 288px */;
}

.w-100 {
  width: 24rem/* 384px */;
}

@media (min-width: 768px) {
  .md\:h-120 {
    width: 28rem/* 384px */;
  }
  .md\:h-screen-75{
    height: calc(100vh - 15vh);
  }
}

@media (min-width: 768px) {
  .md\:w-120 {
    width: 40rem/* 384px */;
  }
}

@media (min-width: 1024px) {
  .lg\:h-120 {
    height: 40rem/* 288px */;
  }
}

@media (min-width: 768px) {
  .md\:w-100 {
    width: 24rem/* 384px */;
  }
}

@media (min-width: 1024px) {
  .lg\:h-100 {
    height: 24rem/* 288px */;
  }
  .lg\:h-screen-75{
    height: calc(100vh - 15vh);
  }
}


nav {
  width: 100vw;
  position: fixed;
  top: 0;
  line-height: 4em;
  text-align: center;
  background-color: white;
  font-family: sans-serif;
  transition: all 0.5s;
  z-index: 100;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

nav.nav-bar--hidden {
  transform: translateY(-100%);
  transition: 0.3s linear;
}

@media (min-width: 768px) {
  .md\:text-gray-big {
    --tw-text-opacity: 1;
    color: #1e1e1e;
  }
  .md\:text-gray-small {
    --tw-text-opacity: 1;
    color: #696969;
  }
  .md\:border-nav{
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
    /*background: var(--BG, #FAFAFA);*/
  }
  .md\:h-105 {
    height: 30rem/* 384px */;
  }

  .blog-h {
    height: 30rem !important;
  }

  .blog-h-all {
    height: 39rem !important;
  }

  .event-h {
    height: 20rem !important;
  }

  .project-h {
    height: 30rem !important;
  }

  .blog-img-h{
    min-height: 17rem !important;
    max-height: 17rem !important;
  }

}
